/* eslint-disable @typescript-eslint/class-name-casing */
/* 
  Created by no name at 01-06-2021 11:33:40
  Màn hình quản lí bán cho thuê
*/

import { BaseStatusProject, NameStatus } from 'constants/Status';
import { translate } from 'translate/language';

export interface SaleAndRentalProps {}

export interface ReqListsalesandRent {
  search?: string;
  realEstate?: string;
  type?: string;
  status?: string;
  limit: number;
  page: number;
  createdType?: string;
  newsConfigType?: string;
  startDate?: string;
  endDate?: string;
}

export interface SaleAndRent {
  childRealEstate: { _id: string; name: string }[];
  childRealEstateId: string;
  code: string;
  createdAt: string;
  createdBy: { _id: string; type: string; name: string; phone?: string };
  landName: string;
  price: number;
  props: ProjectNewsProps;
  status: string;
  _id: string;
  newsConfigType?: string;
}

export interface ProjectNewsProps {
  bathroomNumber?: number;
  bedroomNumber?: number;
  area?: number;
  apartmentCode?: string;
  width?: number;
  height?: number;
  hight?: number;
  floor?: number;
  block?: number | undefined;
}

export enum ProjectNewsStatus {
  PENDING = 'PENDING', //  chờ duyệt
  APPROVED = 'APPROVED', //  đã duyệt
  REJECTED = 'REJECTED', //  từ chối
  SOLD = 'SOLD', //  đã bán
}
export const nameProjectNewStatus: any = {
  [ProjectNewsStatus.PENDING]: 'ctv.pending',
  [ProjectNewsStatus.APPROVED]: 'ctv.approved',
  [ProjectNewsStatus.REJECTED]: 'ctv.refuse',
  [ProjectNewsStatus.SOLD]: 'ctv.sold',
};

export const ListStatusSR = [
  {
    _id: ProjectNewsStatus.PENDING,
    name: nameProjectNewStatus[ProjectNewsStatus.PENDING],
  },
  {
    _id: ProjectNewsStatus.APPROVED,
    name: nameProjectNewStatus[ProjectNewsStatus.APPROVED],
  },
  {
    _id: ProjectNewsStatus.REJECTED,
    name: nameProjectNewStatus[ProjectNewsStatus.REJECTED],
  },
];

export const ListStatusProject = [
  {
    _id: BaseStatusProject.ACTIVE,
    name: NameStatus[BaseStatusProject.ACTIVE],
  },
  {
    _id: BaseStatusProject.ACTIVE_NOT_VERIFIED,
    name: NameStatus[BaseStatusProject.ACTIVE_NOT_VERIFIED],
  },
  {
    _id: BaseStatusProject.FOR_SALE,
    name: NameStatus[BaseStatusProject.FOR_SALE],
  },
  {
    _id: BaseStatusProject.INACTIVE,
    name: NameStatus[BaseStatusProject.INACTIVE],
  },
  {
    _id: BaseStatusProject.NOT_FOR_SALE,
    name: NameStatus[BaseStatusProject.NOT_FOR_SALE],
  },
];

export enum ProjectNewsType {
  SELL = 'SELL',
  RENT = 'RENT',
}

export const listDepositeType = [
  {
    _id: ProjectNewsType.SELL,
    name: 'real_estate.sell',
  },
  { _id: ProjectNewsType.RENT, name: 'real_estate.rent' },
];

export interface ReqCity {
  limit: number;
  page: number;
}
export interface ReqDistrict {
  limit: number;
  page: number;
  cityId: string;
}
export interface ReqWard {
  limit: number;
  page: number;
  districtId: string;
}

export enum InputType {
  CITY = 'city',
  DISTRICT = 'district',
  WARD = 'ward',
  DIRECTION = 'direction',
}

export interface formCreate {
  status: string;
  type: string;
  childRealEstateId: string;
  projectName: string;
  landName: string;
  addressName: string;
  block: number;
  floor: number;
  contacts: string[];
  apartmentCode: string;
  reverseCommission?: number;
  area: number;
  height: number;
  hight: number;
  width: number;
  directionId: string;
  bedroomNumber: number;
  bathroomNumber: number;
  phonePost?: string;
  namePost?: string;
  price: number;
  commissionFee: number;
  mediaIds: string;
  videoUrl: string;
  description: string;
  ownerId: string;
  defaultSupporter?: any;
  days?: number;
  customOwnerName?: string;
  customOwnerPhone?: string;
  customOwnerEmail?: string;
  customOwnerIdentify?: string;
}

export interface ReqCreateRealEstate {
  days?: number;
  newsConfigType?: string;
  ownerType?: string;
  ownerId?: string | undefined;
  customeOwner?: CustomOwner;
  directionId: string;
  props?: ProjectNewsProps;
  commissionFee: number;
  parentRealEstateId: string;
  childRealEstateId: string;
  type: string;
  location: Location;
  status: string;
  videoUrl: string[];
  contacts: string[];
  reverseCommission?: number;
  mediaIds: string[];
  description: string;
  price: number;
  landName: string;
  projectName: string;
  defaultSupporter?: any;
}
export interface Location {
  lat: number;
  lng: number;
  addressName: string;
  village?: string;
  district?: string;
  city?: string;
}

export enum OwnerType {
  AGENCY = 'AGENCY',
  OWNER = 'OWNER',
  NO_AGENCY = 'NO_AGENCY',
}

export interface CustomOwner {
  name: string;
  phone: string;
  email: string;
  identify: string;
}

export const configurations: any = {
  CHUNGCU: [
    {
      key: 'area',
      name: 'Diện tích (M2)',
      isNumber: true,
      rules: [
        {
          required: true,
          message: 'Diện tích là bắt buộc',
        },
      ],
    },
    {
      key: 'block',
      name: 'Block',
      rules: [
        {
          required: true,
          message: 'Block là bắt buộc',
        },
      ],
    },
    {
      key: 'floor',
      name: 'Tầng',
      isNumber: true,
      rules: [
        {
          required: true,
          message: 'Tầng là bắt buộc',
        },
      ],
    },
    {
      key: 'apartmentCode',
      name: 'Mã căn',
      rules: [
        {
          required: true,
          message: 'Mã căn là bắt buộc',
        },
      ],
    },
    // {
    //   key: 'bedroomNumber',
    //   name: 'Số phòng ngủ',
    //   isNumber: true,
    //   rules: [],
    // },
    {
      key: 'bathroomNumber',
      name: 'Số phòng tắm',
      isNumber: true,
      rules: [
        {
          required: true,
          message: 'Số phòng tắm là bắt buộc',
        },
      ],
    },
  ],

  BIETTHU: [
    {
      key: 'area',
      name: 'Diện tích (M2)',
      rules: [
        {
          required: true,
          message: 'Diện tích là bắt buộc',
        },
      ],
      isNumber: true,
    },
    // {
    //   key: 'bedroomNumber',
    //   name: 'Số phòng ngủ',
    //   rules: [],
    //   isNumber: true,
    // },
    {
      key: 'bathroomNumber',
      name: 'Số phòng tắm',
      isNumber: true,
      rules: [
        {
          required: true,
          message: 'Số phòng tắm là bắt buộc',
        },
      ],
    },
    {
      key: 'floor',
      name: 'Tầng',
      isNumber: true,
      rules: [
        {
          required: true,
          message: 'Tầng là bắt buộc',
        },
      ],
    },
    {
      key: 'apartmentCode',
      name: 'Mã căn',
      rules: [
        {
          required: true,
          message: 'Mã căn là bắt buộc',
        },
      ],
    },
    {
      key: 'width',
      name: 'Chiều ngang',
      isNumber: true,
      // rules: [
      //   {
      //     required: true,
      //     message: 'Chiều ngang là bắt buộc',
      //   },
      // ],
    },
    {
      key: 'height',
      name: 'Chiều dài',
      isNumber: true,
      // rules: [
      //   {
      //     required: true,
      //     message: 'Chiều dài là bắt buộc',
      //   },
      // ],
    },
    {
      key: 'hight',
      name: 'Độ cao',
      isNumber: true,
      // rules: [
      //   {
      //     required: true,
      //     message: 'Độ cao là bắt buộc',
      //   },
      // ],
    },
  ],

  MATBANG: [
    {
      key: 'area',
      name: 'Diện tích (M2)',
      rules: [
        {
          required: true,
          message: 'Diện tích là bắt buộc',
        },
      ],
      isNumber: true,
    },
    {
      key: 'apartmentCode',
      name: 'Mã nền',
      // rules: [
      //   {
      //     required: true,
      //     message: 'Mã nền là bắt buộc',
      //   },
      // ],
    },
    {
      key: 'width',
      name: 'Chiều ngang',
      isNumber: true,
      // rules: [
      //   {
      //     required: true,
      //     message: 'Chiều ngang là bắt buộc',
      //   },
      // ],
    },
    {
      key: 'height',
      name: 'Chiều dài',
      isNumber: true,
      // rules: [
      //   {
      //     required: true,
      //     message: 'Độ cao là bắt buộc',
      //   },
      // ],
    },
  ],

  NHAPHO: [
    {
      key: 'floor',
      name: 'Tầng',
      isNumber: true,
      rules: [
        {
          required: true,
          message: 'Tầng là bắt buộc',
        },
      ],
    },
    {
      key: 'apartmentCode',
      name: 'Mã căn',
      rules: [
        {
          required: true,
          message: 'Mã căn là bắt buộc',
        },
      ],
    },
    {
      key: 'area',
      name: 'Diện tích (M2)',
      rules: [
        {
          required: true,
          message: 'Diện tích là bắt buộc',
        },
      ],
      isNumber: true,
    },
    // {
    //   key: 'bedroomNumber',
    //   name: 'Số phòng ngủ',
    //   isNumber: true,
    //   rules: [],
    // },
    {
      key: 'bathroomNumber',
      name: 'Số phòng tắm',
      isNumber: true,
      rules: [
        {
          required: true,
          message: 'Số phòng tắm là bắt buộc',
        },
      ],
    },
    {
      key: 'width',
      name: 'Chiều ngang',
      isNumber: true,
      // rules: [
      //   {
      //     required: true,
      //     message: 'Chiều ngang là bắt buộc',
      //   },
      // ],
    },
    {
      key: 'height',
      name: 'Chiều dài',
      isNumber: true,
      // rules: [
      //   {
      //     required: true,
      //     message: 'Chiều dài là bắt buộc',
      //   },
      // ],
    },
    {
      key: 'hight',
      name: 'Độ cao',
      isNumber: true,
      // rules: [
      //   {
      //     required: true,
      //     message: 'Độ cao là bắt buộc',
      //   },
      // ],
    },
  ],

  SANGNHUONG: [
    {
      key: 'area',
      name: 'Diện tích (M2)',
      rules: [
        {
          required: true,
          message: 'Diện tích là bắt buộc',
        },
      ],
      isNumber: true,
    },
    {
      key: 'height',
      name: 'Chiều dài',
      isNumber: true,
      // rules: [
      //   {
      //     required: true,
      //     message: 'Chiều dài là bắt buộc',
      //   },
      // ],
    },
    {
      key: 'width',
      name: 'Chiều ngang',
      isNumber: true,
      // rules: [
      //   {
      //     required: true,
      //     message: 'Chiều ngang là bắt buộc',
      //   },
      // ],
    },
  ],
};
