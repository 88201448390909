/* eslint-disable import/order */
/* eslint-disable object-shorthand */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
// @ts-ignore
import { Column } from '@ant-design/charts';
import { Card, Col, ConfigProvider, DatePicker, Divider, Layout, Row, Select, Typography } from 'antd';
import locale from 'antd/lib/locale/vi_VN';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusResponse } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { formatNumber } from 'helpers/Utils';
import useLitsProject from 'hooks/Project/useLitsProject';
import moment from 'moment';
import ApiHelper from 'network/ApiClient';
import HistoryPointScreen from 'pages/HistoryBuyPoint/history-point.screen';
import { STATUS_TYPE } from 'pages/HouseTemplate/modelHouseTemplate/HouseTemplate.type';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import useAnalysisService from './analysis.service';
import EmailComponent from './components/email.component';
import ProfitComponent from './components/profit.component';
import PropertyComponent from './components/property.component';
import UserIntroduceComponent from './components/user-introduce.component';
import UserOfflineComponent from './components/user-offline.component';
import './dasbroad.less';
import { formatNumberPrice } from 'helpers/FormatPrice';

const { RangePicker } = DatePicker;

const { Content } = Layout;
const { Title, Text } = Typography;

const HomePage = () => {
  const user = useSelector((state: any) => {
    return state.userReducer;
  });
  if (!user) {
    return <Redirect exact to={ROUTER_PATH.LOGIN} />;
  }

  const { onGetDataProject, dataProject, dataProjectId, onGetDetail, dataDetail } = useAnalysisService();
  const [dataProjectChart, setDataProjectChart] = useState<{ type: string; sales: number }[]>([]);
  const { onListProject, listProject } = useLitsProject();

  useEffect(() => {
    onListProject({ status: STATUS_TYPE.ACTIVE });
    onGetDetail();
  }, []);

  useEffect(() => {
    if (listProject && listProject.data && listProject.data.length > 0) {
      onGetDataProject({ projectId: listProject.data[0]._id });
    }
  }, [listProject]);

  useEffect(() => {
    if (dataProjectId) {
      const data: any = dataProjectId.map((e: any) => {
        return {
          type: translate(`projectType.${e.status}`),
          sales: e.value,
        };
      });
      setDataProjectChart(data);
    }
  }, [dataProjectId]);

  const config: any = {
    data: dataProjectChart,
    xField: 'type',
    yField: 'sales',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    meta: {
      sales: { alias: 'Số căn: ' },
    },
  };

  const handleChangeProject = (projectId: string) => {
    onGetDataProject({ projectId: projectId });
  };

  // Thống kê người dùng APP
  const [filterDate, setFilterDate] = useState<{ minDate: string; maxDate: string }>({
    minDate: moment(moment().add(-30, 'days')).format('DD/MM/YYYY'),
    maxDate: moment(moment().add(-1, 'days')).format('DD/MM/YYYY'),
  });

  const onChangePicker = (dates: any, dateStrings: any) => {
    if (dateStrings && dateStrings.length > 0) {
      setFilterDate({ ...filterDate, minDate: dateStrings[0] || undefined, maxDate: dateStrings[1] || undefined });
    }
  };

  const [dataRegister, setDataRegister] = useState([]);
  const [dataOnline, setDataOnline] = useState([]);

  useEffect(() => {
    asyncFetch(filterDate);
  }, [filterDate]);

  const asyncFetch = async (data: { minDate: string; maxDate: string }) => {
    const res: any = await ApiHelper.fetch('/analysis/user-app', data);
    if (res && res.status === StatusResponse.SUCCESS) {
      setDataRegister(res.userRegister);
      setDataOnline(res.userOnline);
    }
  };

  const configRegister: any = {
    data: dataRegister,
    xField: 'date',
    yField: 'value',
    xAxis: { label: { autoRotate: false } },
    scrollbar: { type: 'horizontal' },
    meta: {
      value: { alias: 'SL đăng ký: ' },
    },
  };

  const configOnline: any = {
    data: dataOnline,
    xField: 'date',
    yField: 'value',
    xAxis: { label: { autoRotate: false } },
    scrollbar: { type: 'horizontal' },
    meta: {
      value: { alias: 'SL online: ' },
    },
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.STATISTICS) && getCheckPermissionUser(PERMISSION_NAME.STATISTICS).read ? (
        <Content className="content">
          <div className="site-card-wrapper">
            <Row gutter={[8, 16]} className="card_top">
              <Col className="gutter-row" span={4}>
                <Card className="card" title="Dự án" bordered={false}>
                  {formatNumber(dataDetail?.totalProject || 0)}
                </Card>
              </Col>
              <Col className="gutter-row" span={4}>
                <Card className="card" title="BĐS lẻ" bordered={false}>
                  {formatNumber(dataDetail?.totalRetailRealEstate || 0)}
                </Card>
              </Col>
              <Col className="gutter-row" span={4}>
                <Card className="card" title="Tổng user" bordered={false}>
                  {formatNumber(dataDetail?.totalUser || 0)}
                </Card>
              </Col>
              <Col className="gutter-row" span={4}>
                <Card className="card" title="User hoạt động" bordered={false}>
                  {formatNumber(dataDetail?.totalUserActive || 0)}
                </Card>
              </Col>
              <Col className="gutter-row" span={4}>
                <Card className="card" title="User đang online" bordered={false}>
                  {formatNumber(dataDetail?.totalUserOnline || 0)}
                </Card>
              </Col>
            </Row>
            <Row gutter={[8, 16]} className="card_top">
              <Col className="gutter-row" span={12}>
                <Card className="card" title="Tổng hoa hồng dự án" bordered={false}>
                  {formatNumberPrice(dataDetail?.totalProjectCommission || 0)}
                </Card>
              </Col>
              <Col className="gutter-row" span={12}>
                <Card className="card" title="Tổng hoa hồng BĐS lẻ" bordered={false}>
                  {formatNumberPrice(dataDetail?.totalProjectNewsCommission || 0)}
                </Card>
              </Col>
            </Row>
          </div>
          {/* Thống kê dự án */}
          {dataProject && (
            <>
              <Divider />
              <Row>
                <Col span={7}>
                  <Title level={2}>Dự án</Title>
                </Col>
                {listProject && listProject.data.length > 0 && (
                  <Col span={7}>
                    <Select
                      onChange={handleChangeProject}
                      style={{ width: '100%' }}
                      defaultValue={listProject.data[0]._id}
                    >
                      {listProject.data.map((e: any) => {
                        return <Select.Option value={e._id}>{e.name}</Select.Option>;
                      })}
                    </Select>
                  </Col>
                )}

                <Col span={15} style={{ marginTop: 40 }}>
                  <Column {...config} />
                </Col>
              </Row>
              <Row style={{ marginTop: '15px' }}>
                <Col span={9}>
                  <Text strong style={{ fontSize: '20px' }}>
                    Tổng số người quan tâm tới dự án
                  </Text>
                </Col>
                <Col span={12}>
                  <span style={{ color: '#c31615', fontWeight: 'bold', marginLeft: '80px', fontSize: '20px' }}>
                    {dataProject.totalUserLikeProject || 0}
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: '15px' }}>
                <Col span={9}>
                  <Text strong style={{ fontSize: '20px' }}>
                    Tổng số khách được thêm vào dự án
                  </Text>
                </Col>
                <Col span={12}>
                  <span style={{ color: '#c31615', fontWeight: 'bold', marginLeft: '80px', fontSize: '20px' }}>
                    {dataProject.totalUserCustomerInProject || 0}
                  </span>
                </Col>
              </Row>
            </>
          )}
          <Divider />
          <div>
            <ProfitComponent />
          </div>
          <Divider />
          {/* Bất động sản lẻ */}
          <PropertyComponent />
          <Divider />
          {/* Xác nhận hợp đồng */}
          <EmailComponent />
          <Divider />
          {/* Danh sách user không online */}
          <UserOfflineComponent />
          <Divider />
          {/* Người dùng APP */}
          <>
            <Row>
              <Col span={24} style={{ marginBottom: '20px' }}>
                <Title level={2}>Người dùng App</Title>
              </Col>
              <Col span={4} style={{ marginBottom: '20px' }}>
                Thời gian
              </Col>
              <Col span={18} style={{ marginBottom: '20px' }}>
                <ConfigProvider locale={locale}>
                  <RangePicker
                    onChange={onChangePicker}
                    allowClear={false}
                    style={{ width: '300px' }}
                    placeholder={['Thời gian bắt đầu', 'Thời gian kết thúc']}
                    defaultValue={[
                      moment(moment().add(-30, 'days'), 'DD/MM/YYYY') as any,
                      moment(moment().add(-1, 'days'), 'DD/MM/YYYY') as any,
                    ]}
                    format="DD/MM/YYYY"
                  />
                </ConfigProvider>
              </Col>
              <Col span={11}>
                <div style={{ marginBottom: '20px' }}>
                  <b>Số lượng đăng ký</b>
                </div>
                {/* @ts-ignore */}
                <Column {...configRegister} />
              </Col>
              <Col span={11} offset={2}>
                <div style={{ marginBottom: '20px' }}>
                  <b>Số lượng Online</b>
                </div>
                {/* @ts-ignore */}
                <Column {...configOnline} />
              </Col>
            </Row>
          </>
          {/* Danh sách user giới thiệu */}
          <Divider />
          <UserIntroduceComponent />

          <Divider />
          <Col span={24} style={{ marginBottom: '20px' }}>
            <Title level={2}>Lịch sử mua gói điểm</Title>
          </Col>
          <HistoryPointScreen />
        </Content>
      ) : (
        ''
      )}
    </>
  );
};

export default HomePage;
