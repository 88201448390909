/* eslint-disable react/require-default-props */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Select } from 'antd';
import React from 'react';
import { translate } from 'translate/language';
import { BaseStatusCollab } from '../types/ctv.types';
import { getCtvStatusColor } from '../utils/ctv.utils';

const { Option } = Select;

interface Props {
  status: BaseStatusCollab | undefined;
  onChangeStatus: (status: BaseStatusCollab | '') => void;
  showAll: boolean | undefined;
  isFillter: boolean | undefined;
  isCollaborator: boolean | undefined;
  disabled?: boolean;
}

export function CtvSelect(props: Props) {
  const { status, onChangeStatus, showAll, isFillter, isCollaborator, disabled } = props;
  return (
    <Select
      defaultValue={status}
      style={{
        width: '100%',
        height: 45,
        color: getCtvStatusColor(status),
      }}
      onSelect={onChangeStatus}
      placeholder={translate('status.name')}
      disabled={disabled}
    >
      {showAll && <Option value="">{translate('status.all')}</Option>}
      <Option
        value={BaseStatusCollab.APPROVED}
        disabled={
          status === BaseStatusCollab.APPROVED_SUPPORTER ||
          status === BaseStatusCollab.PENDING_SUPPORTER ||
          status === BaseStatusCollab.REFUSE_SUPPORTER
        }
        style={{ color: '#11A1FD' }}
      >
        {translate('ctv.approved')}
      </Option>
      <Option
        value={BaseStatusCollab.PENDING}
        disabled={
          status === BaseStatusCollab.APPROVED_SUPPORTER ||
          status === BaseStatusCollab.PENDING_SUPPORTER ||
          status === BaseStatusCollab.REFUSE_SUPPORTER ||
          status === BaseStatusCollab.APPROVED ||
          status === BaseStatusCollab.REFUSE
        }
        style={{ color: '#ff9931' }}
      >
        {translate('ctv.pending')}
      </Option>
      <Option
        value={BaseStatusCollab.REFUSE}
        disabled={
          status === BaseStatusCollab.APPROVED_SUPPORTER ||
          status === BaseStatusCollab.PENDING_SUPPORTER ||
          status === BaseStatusCollab.REFUSE_SUPPORTER
        }
        style={{ color: '#A6ADB4' }}
      >
        {translate('ctv.refuse')}
      </Option>
      <Option
        value={BaseStatusCollab.APPROVED_SUPPORTER}
        disabled={
          (!isFillter && !isCollaborator) ||
          status === BaseStatusCollab.PENDING_SUPPORTER ||
          status === BaseStatusCollab.REFUSE_SUPPORTER ||
          status === BaseStatusCollab.APPROVED ||
          status === BaseStatusCollab.PENDING ||
          status === BaseStatusCollab.REFUSE
        }
        style={{ color: '#11A1FD' }}
      >
        {translate('ctv.approvedSupported')}
      </Option>
      <Option
        value={BaseStatusCollab.PENDING_SUPPORTER}
        disabled={
          (!isFillter && !isCollaborator) ||
          status === BaseStatusCollab.APPROVED_SUPPORTER ||
          status === BaseStatusCollab.REFUSE_SUPPORTER ||
          status === BaseStatusCollab.APPROVED ||
          status === BaseStatusCollab.PENDING ||
          status === BaseStatusCollab.REFUSE
        }
        style={{ color: '#ff9931' }}
      >
        {translate('ctv.pendingSupported')}
      </Option>
      <Option
        value={BaseStatusCollab.REFUSE_SUPPORTER}
        disabled={
          (!isFillter && !isCollaborator) ||
          status === BaseStatusCollab.APPROVED_SUPPORTER ||
          status === BaseStatusCollab.PENDING_SUPPORTER ||
          status === BaseStatusCollab.APPROVED ||
          status === BaseStatusCollab.PENDING ||
          status === BaseStatusCollab.REFUSE
        }
        style={{ color: '#A6ADB4' }}
      >
        {translate('ctv.refuseSupported')}
      </Option>
    </Select>
  );
}
