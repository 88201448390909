/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { EyeOutlined } from '@ant-design/icons';
import { useDebounceFn } from 'ahooks';
import { Col, Pagination, Popconfirm, Row, Table } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import images from 'images';
import _ from 'lodash';
// @ts-ignore
import renderHTML from 'react-render-html';
import moment from 'moment';
import useNotificationService from 'pages/Notification/notification.service';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterNotificationComponent from './components/Filter.component';
import { FilterNotification, Payload, UserInfo } from './notification.type';

function ListNotificationPage(): JSX.Element {
  const history = useHistory();
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<FilterNotification>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    search: undefined,
    dateReceiveNotification: undefined,
  });

  const { onGetNotification, data, totalData, loading, onDeleteNotification } = useNotificationService();

  useEffect(() => {
    onGetNotification(filter);
  }, [filter]);

  const renderAction = (_id: string) => {
    return (
      <div className="flex render-action-style justiv">
        <span
          onClick={() => {
            history.push(ROUTER_PATH.DETAIL_NOTIFICATION + _id);
          }}
          style={{ position: 'relative', top: '5px', fontSize: '20px', left: '-5px', cursor: 'pointer' }}
        >
          <EyeOutlined />
        </span>

        <Popconfirm
          placement="top"
          title={translate('action.name')}
          onConfirm={() => onDelete(_id)}
          okText={translate('action.yes')}
          cancelText={translate('action.no')}
        >
          <img className="img-style" src={images.ic_delete} alt="" />
        </Popconfirm>
      </div>
    );
  };

  const columns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Họ và tên người nhận',
      dataIndex: 'userInfo',
      render: (userInfo: UserInfo) => {
        return <span>{(userInfo && userInfo.name) || ''}</span>;
      },
      width: '10%',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'userInfo',
      render: (userInfo: UserInfo) => {
        return <span>{(userInfo && userInfo.phone) || ''}</span>;
      },
      width: '10%',
    },
    {
      title: 'Chứng minh thư',
      dataIndex: 'userInfo',
      render: (userInfo: UserInfo) => {
        return <span>{(userInfo && userInfo.identify) || ''}</span>;
      },
      width: '10%',
    },
    {
      title: 'Nội dung thông báo',
      dataIndex: 'body',
      render: (body: string) => {
        return <span>{body ? renderHTML(body) : ''}</span>;
      },
      width: '20%',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      render: (createdAt: moment.Moment) => {
        return <span>{moment(createdAt).format('HH:mm DD/MM/YYYY')}</span>;
      },
      width: '10%',
    },
    {
      title: 'Yêu cầu dịch thành công',
      dataIndex: 'payload',
      render: (payload: Payload) => {
        return <span>{(payload && payload.curentTransactionSuccess) || 0}</span>;
      },
      width: '10%',
    },
    {
      title: 'Yêu cầu giới thiệu thành viên',
      dataIndex: 'payload',
      render: (payload: Payload) => {
        return <span>{(payload && payload.numberUserIntroduceOfRule) || 0}</span>;
      },
      width: '10%',
    },
    {
      title: 'Tùy chọn',
      dataIndex: '_id',
      render: renderAction,
    },
  ];

  const onDelete = (_id: string) => {
    onDeleteNotification([_id]);
    setFilter({ ...filter, page: 1 });
  };

  // Thay đổi Page
  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  // Tìm kiếm theo tên gói
  const { run } = useDebounceFn(
    (search: string) => {
      setFilter({ ...filter, search: search });
    },
    {
      wait: 500,
    },
  );

  // Thay đổi trạng thái
  const onSearchDate = (data: any) => {
    setFilter({ ...filter, dateReceiveNotification: data ? moment(data).format('DD/MM/YYYY') : undefined });
  };

  // Action Xóa nhiều
  const [dataDelete, setDataDelete] = useState<string[]>([]);

  const onDeleteAll = () => {
    onDeleteNotification(dataDelete);
    setDataDelete([]);
    setFilter({ ...filter, page: 1 });
  };

  const rowSelection = {
    selectedRowKeys: dataDelete,
    onChange: (selectedRowKeys: string[]) => {
      const arrDelete = dataDelete.filter(_id => !data.find(item => _id === item._id));
      setDataDelete(_.uniq([...arrDelete, ...selectedRowKeys]));
    },
  };

  return (
    <>
      <FilterNotificationComponent onSearch={(data: string) => run(data)} onSearchDate={onSearchDate} />
      <Row>
        {loading ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            <div style={{ marginBottom: '10px', cursor: 'pointer' }}>
              {dataDelete && dataDelete.length > 0 && (
                <Popconfirm
                  placement="top"
                  title="Bạn có chắc muốn xóa"
                  onConfirm={() => onDeleteAll()}
                  okText={translate('action.yes')}
                  cancelText={translate('action.no')}
                >
                  <img className="img-style" src={images.ic_delete} alt="" />
                  <span
                    style={{ position: 'relative', top: '3px', color: 'red', fontWeight: 'bold', marginLeft: '3px' }}
                  >
                    Xóa nhiều
                  </span>
                </Popconfirm>
              )}
              <span style={{ position: 'relative', top: '3px', marginLeft: '10px' }}>
                Đã chọn {dataDelete.length || 0}
              </span>
            </div>

            <Table
              rowKey="_id"
              dataSource={data || []}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              columns={columns}
              pagination={false}
              scroll={{ x: 'auto' }}
            />
          </Col>
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalData}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ListNotificationPage;
