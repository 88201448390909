/* eslint-disable no-shadow */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
import { Col, List, Row, Select, Table, Typography, Form, Input, Button } from 'antd';
import { StatusTransactions } from 'constants/Status';
import { formatNumberPrice } from 'helpers/FormatPrice';
import moment from 'moment';
import { useQuery } from 'pages/House/house.screen';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import CustomerItem from './components/customer-chat-item';
import InputChatItem from './components/customer-project-item';
import useCustomerProjectChatService from './customer-project-transaction-chat.services';
import './customer-project.scss';
import useCustomerProjectService from './customer-project.service';
import { Message } from './MessageType';

const { Text, Link, Title } = Typography;
const { Option } = Select;

function CustomerProjectDetail(): JSX.Element {
  const params: { id: string } = useParams();
  const type = useQuery().get('type');
  const [form] = Form.useForm();
  const isEdit = params && params.id;
  const [filterMessage, setFilterMessage] = React.useState<any>({ transactionId: params.id, limit: 20, type: 'CMS' });
  const {
    onDetailTransaction,
    updateViewMessage,
    detailTransaction,
    updateStatus,
    onGetHistory,
    dataHistory,
  } = useCustomerProjectService();
  const {
    history,
    onGetListHistoryMess,
    stopLoadmore,
    ListMember,
    getListMemberPag,
    updateMessage,
  } = useCustomerProjectChatService();
  useEffect(() => {
    if (isEdit) {
      onDetailTransaction(
        {
          transactionId: params.id,
        },
        type || 'PAG',
      );
      if (type === 'PAG') {
        getListMemberPag(params.id);
      }
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && filterMessage) {
      onGetListHistoryMess(filterMessage);
    }
  }, [filterMessage]);

  const onLoadMore = (e: any) => {
    if (e && !stopLoadmore) {
      setFilterMessage({
        ...filterMessage,
        after: history[history.length - 1]._id,
      });
    }
  };

  useEffect(() => {
    if (isEdit) {
      onGetHistory(params.id);
    }
  }, [isEdit]);

  const onUpdateMessage = (idMessage: string, isHide: boolean) => {
    const input = {
      transactionId: params.id,
      idMessage,
      isHide,
    };
    updateMessage(input);
  };
  const onUpdateViewMessage = (userId: string) => {
    const newParams = {
      transactionId: params.id,
      userId,
    };
    updateViewMessage(newParams);
  };

  const columns = [
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status: string) => {
        switch (status) {
          case 'INTRODUCED':
            return translate('transaction.INTRODUCED');
          case 'FOUND_SUPPORTER':
            return translate('transaction.FOUND_SUPPORTER');
          case 'CONFIRMED_INFO':
            return translate('transaction.CONFIRMED_INFO');
          case 'SUPPORTED':
            return translate('transaction.SUPPORTED');
          case 'BOOKING':
            return translate('transaction.BOOKING');
          case 'BOOKED':
            return translate('transaction.BOOKED');
          case 'DEPOSIT_PENDING':
            return translate('transaction.DEPOSIT_PENDING');
          case 'DEPOSIT':
            return translate('transaction.DEPOSIT');
          case 'SUCCESS':
            return translate('transaction.SUCCESS');
          case 'CANCEL_BOOKING':
            return translate('transaction.CANCEL_BOOKING');
          case 'CANCEL_DEPOSIT':
            return translate('transaction.CANCEL_DEPOSIT');
          case 'CUSTOMER_CANCEL':
            return translate('transaction.CUSTOMER_CANCEL');
          default:
            return '';
        }
      },
    },
    {
      title: 'Ngày được set',
      dataIndex: 'createdAt',
      render: (createdAt: moment.Moment) => {
        return <>{moment(createdAt).format('HH:mm DD-MM-YYYY')}</>;
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
    },
  ];

  const historyCommissionColumns = [
    {
      title: 'Số tiền',
      dataIndex: 'money',
      render: (money: number) => {
        return <>{formatNumberPrice(money)}</>;
      },
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      render: (type: string) => {
        switch (type) {
          case 'INTRODUCER':
            return 'HH cho CTV giới thiệu';
          case 'SUPPORTER':
            return 'HH cho CTV hỗ trợ';
          case 'CUSTOMER':
            return 'HH cho khách hàng';
          case 'TREE':
            return 'HH cho hệ thống khách hàng';
          case 'PAG_COMMISSION_FROM_PROJECT':
            return 'HH cho Phúc An Gia';
          default:
            return '';
        }
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status: string) => {
        switch (status) {
          case 'PENDING_APPROVED':
            return 'Chờ duyệt';
          case 'APPROVED':
            return 'Đã duyệt';
          case 'CANCEL':
            return 'Đã huỷ';
          case 'PENDING_PAYMENT':
            return 'Chờ chi trả';
          default:
            return '';
        }
      },
    },
    {
      title: 'Người nhận',
      dataIndex: 'userInfo',
      render: (userInfo: any) => {
        return <>{userInfo ? userInfo.name : 'PAG'}</>;
      },
    },
    {
      title: 'Ngày nhận',
      dataIndex: 'receivedDate',
      render: (receivedDate: moment.Moment) => {
        return <>{moment(receivedDate).format('HH:mm DD-MM-YYYY')}</>;
      },
    },
  ];

  useEffect(() => {
    if (isEdit && detailTransaction) {
      form.setFieldsValue({
        status: detailTransaction.status,
        note: detailTransaction.note,
      });
    }
  }, [detailTransaction]);

  const onFinish = (value: any) => {
    updateStatus({
      transactionId: params.id,
      status: value.status,
      note: value.note,
    });
  };

  const historyBack = useHistory();

  const backHistory = () => {
    historyBack.goBack();
  };
  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_CUSTOMERS_BOOKING).create ||
      getCheckPermissionUser(PERMISSION_NAME.PROJECTS_CUSTOMERS_BOOKING).edit ? (
        <Row>
          <Col span={24}>
            {detailTransaction && (
              <Form form={form} onFinish={onFinish}>
                {/* Tên dự án */}
                <Row justify="center">
                  <Col span={24}>
                    <Row>
                      <Col span={8}>{translate('customer_project.name_project')}</Col>
                      <Col span={14} className="fontSize20">
                        <b>{detailTransaction.projectInfo && detailTransaction.projectInfo.name}</b>
                      </Col>
                    </Row>

                    {/* Tên khách hàng */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.name_customer')}</Col>
                      <Col span={14}>
                        <Link href={ROUTER_PATH.EDIT_CUSTOMER + detailTransaction.userInfo?._id} target="_blank">
                          <Text underline style={{ color: '#12a0c0' }} className="fontSize20">
                            {detailTransaction.userInfo && detailTransaction.userInfo.name}
                          </Text>
                        </Link>
                      </Col>
                    </Row>

                    {/* Số điện thoại */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.phone')}</Col>
                      <Col span={14} className="fontSize20">
                        <b>{detailTransaction.userInfo && detailTransaction.userInfo.phone}</b>
                      </Col>
                    </Row>

                    {/* Số CMND */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.CMND')}</Col>
                      <Col span={14} className="fontSize20">
                        <b>{detailTransaction.userInfo && detailTransaction.userInfo.identify}</b>
                      </Col>
                    </Row>

                    {/* CTV giới thiệu */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.ctv_introducer')}</Col>
                      <Col span={14} className="fontSize20">
                        <Link href={ROUTER_PATH.EDIT_CUSTOMER + detailTransaction.introducerInfo?._id} target="_blank">
                          <Text underline style={{ color: '#12a0c0' }}>
                            {detailTransaction.introducerInfo && detailTransaction.introducerInfo.name}
                          </Text>
                        </Link>
                      </Col>
                    </Row>

                    {/* Số điện thoại CTV giới thiệu */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.phone_ctv_introducer')}</Col>
                      <Col span={14} className="fontSize20">
                        <b>{detailTransaction.introducerInfo && detailTransaction.introducerInfo.phone}</b>
                      </Col>
                    </Row>

                    {/* CTV hỗ trợ */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.ctv_supporter')}</Col>
                      <Col span={14} className="fontSize20">
                        <Link href={ROUTER_PATH.EDIT_CUSTOMER + detailTransaction.supporterInfo?._id} target="_blank">
                          <Text underline style={{ color: '#12a0c0' }}>
                            {detailTransaction.supporterInfo && detailTransaction.supporterInfo.name}
                          </Text>
                        </Link>
                      </Col>
                    </Row>

                    {/* Số điện thoại CTV hỗ trợ */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.phone_ctv_supporter')}</Col>
                      <Col span={14} className="fontSize20">
                        <b>{detailTransaction.supporterInfo && detailTransaction.supporterInfo.phone}</b>
                      </Col>
                    </Row>

                    {/* Mã căn hộ */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.code')}</Col>
                      <Col span={14} className="fontSize20">
                        {detailTransaction.houseInfo ? (
                          <Link href={ROUTER_PATH.EDIT_HOUSE_LOTS + detailTransaction.houseInfo._id} target="_blank">
                            <Text underline style={{ color: '#12a0c0' }}>
                              {detailTransaction.houseInfo && detailTransaction.houseInfo.code}
                            </Text>
                          </Link>
                        ) : (
                          <b>__</b>
                        )}
                      </Col>
                    </Row>

                    {/* Giá bán */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.price')}</Col>
                      {detailTransaction.houseInfo ? (
                        <Col span={14} className="fontSize20">
                          <b> {detailTransaction.houseInfo && formatNumberPrice(detailTransaction.houseInfo.price)}</b>
                        </Col>
                      ) : (
                        <b>__</b>
                      )}
                    </Row>

                    {/* Trạng thái giao dịch */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.status_detail')}</Col>
                      <Col span={5}>
                        <Form.Item name="status">
                          <Select style={{ width: '100%' }}>
                            <Option
                              value={StatusTransactions.INTRODUCED}
                              disabled={
                                detailTransaction.status === StatusTransactions.CONFIRMED_INFO ||
                                detailTransaction.status === StatusTransactions.SUPPORTED ||
                                detailTransaction.status === StatusTransactions.BOOKING ||
                                detailTransaction.status === StatusTransactions.BOOKED ||
                                detailTransaction.status === StatusTransactions.DEPOSIT_PENDING ||
                                detailTransaction.status === StatusTransactions.DEPOSIT ||
                                detailTransaction.status === StatusTransactions.SUCCESS ||
                                detailTransaction.status === StatusTransactions.CANCEL_BOOKING ||
                                detailTransaction.status === StatusTransactions.CANCEL_DEPOSIT ||
                                detailTransaction.status === StatusTransactions.CUSTOMER_CANCEL
                              }
                            >
                              {translate('transaction.INTRODUCED')}
                            </Option>
                            {/* Giới thiệu khách hàng */}
                            <Option
                              value={StatusTransactions.FOUND_SUPPORTER}
                              disabled={
                                detailTransaction.status === StatusTransactions.INTRODUCED ||
                                detailTransaction.status === StatusTransactions.FOUND_SUPPORTER ||
                                detailTransaction.status === StatusTransactions.CONFIRMED_INFO ||
                                detailTransaction.status === StatusTransactions.SUPPORTED ||
                                detailTransaction.status === StatusTransactions.BOOKING ||
                                detailTransaction.status === StatusTransactions.BOOKED ||
                                detailTransaction.status === StatusTransactions.DEPOSIT_PENDING ||
                                detailTransaction.status === StatusTransactions.DEPOSIT ||
                                detailTransaction.status === StatusTransactions.SUCCESS ||
                                detailTransaction.status === StatusTransactions.CANCEL_BOOKING ||
                                detailTransaction.status === StatusTransactions.CANCEL_DEPOSIT ||
                                detailTransaction.status === StatusTransactions.CUSTOMER_CANCEL
                              }
                            >
                              {translate('transaction.FOUND_SUPPORTER')}
                            </Option>
                            {/* Yêu cầu hỗ trợ */}
                            <Option
                              value={StatusTransactions.CONFIRMED_INFO}
                              disabled={
                                detailTransaction.status === StatusTransactions.CONFIRMED_INFO ||
                                detailTransaction.status === StatusTransactions.SUPPORTED ||
                                detailTransaction.status === StatusTransactions.BOOKING ||
                                detailTransaction.status === StatusTransactions.BOOKED ||
                                detailTransaction.status === StatusTransactions.DEPOSIT_PENDING ||
                                detailTransaction.status === StatusTransactions.DEPOSIT ||
                                detailTransaction.status === StatusTransactions.SUCCESS ||
                                detailTransaction.status === StatusTransactions.CANCEL_BOOKING ||
                                detailTransaction.status === StatusTransactions.CANCEL_DEPOSIT ||
                                detailTransaction.status === StatusTransactions.CUSTOMER_CANCEL
                              }
                            >
                              {translate('transaction.CONFIRMED_INFO')}
                            </Option>
                            {/* CTV hỗ trợ xác minh */}
                            <Option
                              value={StatusTransactions.SUPPORTED}
                              disabled={
                                detailTransaction.status === StatusTransactions.SUPPORTED ||
                                detailTransaction.status === StatusTransactions.BOOKING ||
                                detailTransaction.status === StatusTransactions.BOOKED ||
                                detailTransaction.status === StatusTransactions.DEPOSIT_PENDING ||
                                detailTransaction.status === StatusTransactions.DEPOSIT ||
                                detailTransaction.status === StatusTransactions.SUCCESS ||
                                detailTransaction.status === StatusTransactions.CANCEL_BOOKING ||
                                detailTransaction.status === StatusTransactions.CANCEL_DEPOSIT ||
                                detailTransaction.status === StatusTransactions.CUSTOMER_CANCEL
                              }
                            >
                              {translate('transaction.SUPPORTED')}
                            </Option>
                            {/* Hỗ trợ và tư vấn bán hàng */}
                            <Option
                              value={StatusTransactions.BOOKING}
                              disabled={
                                detailTransaction.status === StatusTransactions.INTRODUCED ||
                                detailTransaction.status === StatusTransactions.FOUND_SUPPORTER ||
                                detailTransaction.status === StatusTransactions.CONFIRMED_INFO ||
                                detailTransaction.status === StatusTransactions.SUPPORTED ||
                                detailTransaction.status === StatusTransactions.BOOKING ||
                                detailTransaction.status === StatusTransactions.BOOKED ||
                                detailTransaction.status === StatusTransactions.DEPOSIT_PENDING ||
                                detailTransaction.status === StatusTransactions.DEPOSIT ||
                                detailTransaction.status === StatusTransactions.SUCCESS ||
                                detailTransaction.status === StatusTransactions.CANCEL_BOOKING ||
                                detailTransaction.status === StatusTransactions.CANCEL_DEPOSIT ||
                                detailTransaction.status === StatusTransactions.CUSTOMER_CANCEL
                              }
                            >
                              {translate('transaction.BOOKING')}
                            </Option>
                            {/* Yêu cầu giữ chỗ */}
                            <Option
                              value={StatusTransactions.BOOKED}
                              disabled={
                                detailTransaction.status === StatusTransactions.BOOKED ||
                                detailTransaction.status === StatusTransactions.DEPOSIT_PENDING ||
                                detailTransaction.status === StatusTransactions.DEPOSIT ||
                                detailTransaction.status === StatusTransactions.SUCCESS ||
                                detailTransaction.status === StatusTransactions.CANCEL_BOOKING ||
                                detailTransaction.status === StatusTransactions.CANCEL_DEPOSIT ||
                                detailTransaction.status === StatusTransactions.CUSTOMER_CANCEL
                              }
                            >
                              {translate('transaction.BOOKED')}
                            </Option>
                            {/* Đã giữ chỗ */}
                            <Option
                              value={StatusTransactions.DEPOSIT_PENDING}
                              disabled={
                                detailTransaction.status === StatusTransactions.INTRODUCED ||
                                detailTransaction.status === StatusTransactions.FOUND_SUPPORTER ||
                                detailTransaction.status === StatusTransactions.CONFIRMED_INFO ||
                                detailTransaction.status === StatusTransactions.SUPPORTED ||
                                detailTransaction.status === StatusTransactions.BOOKING ||
                                detailTransaction.status === StatusTransactions.BOOKED ||
                                detailTransaction.status === StatusTransactions.DEPOSIT_PENDING ||
                                detailTransaction.status === StatusTransactions.DEPOSIT ||
                                detailTransaction.status === StatusTransactions.SUCCESS ||
                                detailTransaction.status === StatusTransactions.CANCEL_BOOKING ||
                                detailTransaction.status === StatusTransactions.CANCEL_DEPOSIT ||
                                detailTransaction.status === StatusTransactions.CUSTOMER_CANCEL
                              }
                            >
                              {translate('transaction.DEPOSIT_PENDING')}
                            </Option>
                            {/* Yêu cầu đặt cọc */}
                            <Option
                              value={StatusTransactions.DEPOSIT}
                              disabled={
                                detailTransaction.status === StatusTransactions.DEPOSIT ||
                                detailTransaction.status === StatusTransactions.SUCCESS ||
                                detailTransaction.status === StatusTransactions.CANCEL_BOOKING ||
                                detailTransaction.status === StatusTransactions.CANCEL_DEPOSIT ||
                                detailTransaction.status === StatusTransactions.CUSTOMER_CANCEL
                              }
                            >
                              {translate('transaction.DEPOSIT')}
                            </Option>
                            {/* Đã đặt cọc */}
                            <Option value={StatusTransactions.SUCCESS}>{translate('transaction.SUCCESS')}</Option>
                            {/* Giao dịch thành công */}
                            <Option
                              value={StatusTransactions.CANCEL_BOOKING}
                              disabled={detailTransaction.status === StatusTransactions.SUCCESS}
                            >
                              {translate('transaction.CANCEL_BOOKING')}
                            </Option>
                            {/* Hủy giữ chỗ */}
                            <Option
                              value={StatusTransactions.CANCEL_DEPOSIT}
                              disabled={detailTransaction.status === StatusTransactions.SUCCESS}
                            >
                              {translate('transaction.CANCEL_DEPOSIT')}
                            </Option>
                            {/* Hủy đặt cọc */}
                            <Option
                              value={StatusTransactions.CUSTOMER_CANCEL}
                              disabled={detailTransaction.status === StatusTransactions.SUCCESS}
                            >
                              {translate('transaction.CUSTOMER_CANCEL')}
                            </Option>
                            {/* Giao dịch không thành công */}
                            <Option
                              value="REQUEST_DEPOSIT_PENDING"
                              disabled={detailTransaction.status === StatusTransactions.SUCCESS}
                            >
                              Đang xác nhận chuyển cọc
                            </Option>
                            <Option
                              value="REQUEST_DEPOSIT"
                              disabled={detailTransaction.status === StatusTransactions.SUCCESS}
                            >
                              Đã Xác Nhận chuyển cọc
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    {/* Ghi chú */}
                    <Row className="marginTop">
                      <Col span={8}>Ghi chú</Col>
                      <Col span={16} className="fontSize20">
                        <Form.Item name="note">
                          <Input.TextArea rows={5} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Col>
                            <Form.Item>
                              <Button type="primary" style={{ backgroundColor: 'red' }} onClick={backHistory}>
                                {translate('action.close')}
                              </Button>
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item>
                              <Button type="primary" style={{ backgroundColor: 'rgb(58, 200, 110)' }} htmlType="submit">
                                {translate('action.save')}
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="marginTop">
                      <Col span={11}>
                        <h4>Lịch sử giao dịch</h4>
                        <Table dataSource={dataHistory || []} columns={columns} pagination={false} />
                      </Col>
                      <Col span={1}></Col>
                      <Col span={12}>
                        <h4>Lịch sử hoa hồng</h4>
                        <Table
                          dataSource={detailTransaction.historyCommissionInfo || []}
                          columns={historyCommissionColumns}
                          pagination={false}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* Thảo luận liên quan */}
                <Row className="marginTop">
                  <hr style={{ borderTop: '2px solid black', width: '100%' }} />
                </Row>

                <Row>
                  <Col span={24}>
                    <Title level={3} style={{ textAlign: 'center' }}>
                      Thảo luận liên quan
                    </Title>
                  </Col>
                </Row>
                {/* Danh sách thảo luận   */}
                <Row className="marginTop">
                  <Col span={15}>
                    <div className="demo-infinite-container">
                      <InfiniteScroll
                        initialLoad={false}
                        pageStart={0}
                        loadMore={onLoadMore}
                        hasMore={true}
                        useWindow={false}
                      >
                        <List
                          className="comment-list"
                          itemLayout="horizontal"
                          dataSource={history}
                          renderItem={(item: Message) => (
                            <InputChatItem onUpdateMessage={onUpdateMessage} item={item} />
                          )}
                        />
                      </InfiniteScroll>
                    </div>
                  </Col>

                  <Col span={4} offset={1}>
                    <List
                      className="comment-list"
                      itemLayout="horizontal"
                      dataSource={ListMember}
                      renderItem={(item: any) => (
                        <CustomerItem
                          detailTransaction={detailTransaction}
                          onUpdateViewMessage={onUpdateViewMessage}
                          item={item}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default CustomerProjectDetail;
