import { getUrlExport } from 'helpers/getImageUrl';
import { translate } from 'translate/language';
/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ToastError } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';

export interface TUSER_OFFLINE {
  page: number | string;
  limit: number | string;
  numberDay: number;
}

export interface USER_F1 {
  page: number | string;
  limit: number | string;
  minNumberUser: number;
  maxNumberUser: number;
}

const URL_DETAIL = '/analysis/detail';
const URL_REAL_ESTATE = '/analysis/retail-real-estate';
const URL_PROJECT = '/analysis/project';
const URL_USER_OFFLINE = '/analysis/list-user-offline';
const URL_USER_F1 = '/analysis/list-user-has-child';
const URL_EMAIL = '/analysis/contract-email';

interface DataRetailRealEstate {
  status: string;
  totalUserCareRetailRealEstateAgency: number;
  totalUserCareRetailRealEstateOwner: number;
  totalUserCareRetailRealEstateNoAgency: number;
  totalUserCareRetailRealEstatePag: number;
}

function useAnalysisService() {
  const [dataRetailRealEstate, setDataRetailRealEstate] = useState<DataRetailRealEstate | undefined>(undefined);
  const [dataProject, setDataProject] = useState<any>();
  const [dataProjectId, setDataProjectId] = useState<any[]>([]);
  const [dataUserOffline, setDataUserOffline] = useState<any[]>([]);
  const [totalUserOffline, setTotalUserOffline] = useState<number>();
  const [loadingUserOffline, setLoadingUserOffline] = useState<boolean>(false);
  const [dataDetail, setDataDetail] = useState<any>(undefined);

  const [dataUserF1, setDataUserF1] = useState<any[]>([]);
  const [totalUserF1, setTotalUserF1] = useState<number>();
  const [loadingUserF1, setLoadingUserF1] = useState<boolean>(false);

  const [dataEmailHome, setDataEmail] = useState<any>();
  const [totalEmail, setTotalEmail] = useState<number>();
  const [loadingEmail, setLoadingEmail] = useState<boolean>(false);

  const [dataProfit, setDataProfit] = useState<any[]>([]);
  const [summaryProfit, setSummaryProfit] = useState<any>();
  const [totalProfit, setTotalProfit] = useState<number>();
  const [loadingProfit, setLoadingProfit] = useState<boolean>(false);

  // Thống kê tổng quan
  const onGetDetail = async () => {
    const res: any = await ApiHelper.fetch(URL_DETAIL);
    if (res && res.status === StatusResponse.SUCCESS) {
      setDataDetail(res);
    } else {
      setDataDetail(undefined);
    }
  };

  // Thống kê lợi nhuận
  const getProfit = async (param: any) => {
    setLoadingProfit(true);
    const res: any = await ApiHelper.fetch('/analysis/pag-profit', param);
    if (res && res.dataFile) {
      window.open(getUrlExport(res.dataFile));
      setLoadingProfit(false);
    }
    if (res && res.status === StatusResponse.SUCCESS) {
      setDataProfit(res.data);
      setTotalProfit(res.total);
      setSummaryProfit({
        profitFromPointPackage: res.profitFromPointPackage,
        profitFromProject: res.profitFromProject,
        profitFromProjectNews: res.profitFromProjectNews,
        totalProfit: res.totalProfit,
      });
      setLoadingProfit(false);
    } else {
      setDataProfit([]);
      setTotalProfit(0);
      setLoadingProfit(false);
    }
  };

  const onGetDataEstate = async () => {
    const res: any = await ApiHelper.fetch(URL_REAL_ESTATE);
    if (res && res.status === StatusResponse.SUCCESS) {
      setDataRetailRealEstate(res);
    } else {
      setDataRetailRealEstate(undefined);
    }
  };

  const onGetDataProject = async (data: { projectId: string }) => {
    const res: any = await ApiHelper.fetch(URL_PROJECT, data);
    if (res && res.status === StatusResponse.SUCCESS) {
      setDataProject(res);
      setDataProjectId(res.data);
    }
  };

  // Thống kê user không online
  const onGetUserOffline = async (data: TUSER_OFFLINE) => {
    setLoadingUserOffline(true);
    const res: any = await ApiHelper.fetch(URL_USER_OFFLINE, data);
    if (res && res.dataFile) {
      window.open(getUrlExport(res.dataFile));
      setLoadingUserOffline(false);
    }
    if (res && res.status === StatusResponse.SUCCESS) {
      setDataUserOffline(res.data);
      setTotalUserOffline(res.total);
      setLoadingUserOffline(false);
    } else {
      setLoadingUserOffline(false);
    }
  };

  // Thống kê user giới thiệu
  const onGetUserF1 = async (data: USER_F1) => {
    setLoadingUserF1(true);
    if (data.minNumberUser > data.maxNumberUser) {
      setLoadingUserF1(false);
      return ToastError({
        message: translate('message_type.failed'),
        description: 'Dữ liệu truyền vào không đúng định dạng',
      });
    }
    const res: any = await ApiHelper.fetch(URL_USER_F1, data);
    if (res && res.dataFile) {
      window.open(getUrlExport(res.dataFile));
      setLoadingUserF1(false);
    }
    if (res && res.status === StatusResponse.SUCCESS) {
      setDataUserF1(res.data);
      setTotalUserF1(res.total);
      setLoadingUserF1(false);
    } else {
      setLoadingUserF1(false);
    }
  };

  // Thống kê Email
  const onGetEmailHome = async (data: any) => {
    setLoadingEmail(true);
    const res: any = await ApiHelper.fetch(URL_EMAIL, data);
    if (res && res.dataFile) {
      window.open(getUrlExport(res.dataFile));
      setLoadingEmail(false);
    }
    if (res && res.status === StatusResponse.SUCCESS) {
      setDataEmail(res);
      setTotalEmail(res.total);
      setLoadingEmail(false);
    } else {
      setLoadingEmail(false);
    }
  };

  return {
    onGetDataEstate,
    dataRetailRealEstate,
    onGetDataProject,
    dataProject,
    dataProjectId,
    onGetUserOffline,
    dataUserOffline,
    totalUserOffline,
    loadingUserOffline,
    onGetUserF1,
    dataUserF1,
    totalUserF1,
    loadingUserF1,
    onGetDetail,
    dataDetail,
    onGetEmailHome,
    dataEmailHome,
    totalEmail,
    loadingEmail,
    getProfit,
    dataProfit,
    totalProfit,
    loadingProfit,
    summaryProfit,
  };
}

export default useAnalysisService;
