/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusType } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { STATUS_TYPE } from 'pages/HouseTemplate/modelHouseTemplate/HouseTemplate.type';
import useLitsHouseTemplate from 'pages/HouseTemplate/useHouseTemplatehooks/useHouseTemplatehooks';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

const { Option } = Select;

export interface Props {
  onSearch: (data?: string) => void;
  onSearchDate: (data: string) => void;
}

function FilterNotificationComponent(props: Props): JSX.Element {
  const { onSearch, onSearchDate } = props;
  const history = useHistory();
  const [form] = Form.useForm();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value ? event.target.value : undefined);
  };

  // Thay đổi trạng thái
  const handChangeStatus = (value: any) => {
    onSearchDate(value !== '' ? value : undefined);
  };

  return (
    <>
      <Form form={form}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item name="search">
              <Input size="small" placeholder="Tên người nhận" onChange={handleSearch} suffix={<SearchOutlined />} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="customer_type">
              <DatePicker onChange={handChangeStatus} placeholder="Ngày nhận thông báo" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default FilterNotificationComponent;
