/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, ConfigProvider, Pagination, Row, Table, Typography, DatePicker, Tag } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { formatNumber } from 'helpers/Utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import useHistoryUsedPointService from './service';
import locale from 'antd/lib/locale/vi_VN';

const { Link, Text } = Typography;
const { RangePicker } = DatePicker;

const HistoryUsedPointScreen = (): JSX.Element => {
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<any>({
    page,
    limit: PaginateList.LIMIT_DEFAULT,
  });

  const { onGetHistoryUsedPoint, dataHistory, totalHistory, loading } = useHistoryUsedPointService();

  useEffect(() => {
    onGetHistoryUsedPoint(filter);
  }, [filter]);

  const columns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Khách hàng',
      dataIndex: 'userInfo',
      render: (userInfo: any) => {
        return (
          <span>
            <>
              {userInfo ? (
                <Link href={`${ROUTER_PATH.EDIT_CUSTOMER}${userInfo._id}`} target="_blank">
                  <Text underline style={{ color: '#12a0c0' }}>
                    {userInfo.name}
                  </Text>
                </Link>
              ) : (
                ''
              )}
            </>
          </span>
        );
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'userInfo',
      render: (userInfo: any) => {
        return <span>{userInfo ? userInfo.phone : ''}</span>;
      },
    },

    {
      title: 'Tác vụ',
      dataIndex: 'name',
      render: (name: string) => {
        return <span>{name}</span>;
      },
    },
    {
      title: 'Số điểm',
      dataIndex: 'point',
      render: (point: number) => {
        return <Tag>{formatNumber(point)}</Tag>;
      },
    },
    {
      title: 'Thời gian',
      dataIndex: 'createdAt',
      render: (createdAt: moment.Moment) => {
        return <span>{moment(createdAt).format('HH:mm DD/MM/YYYY')}</span>;
      },
    },
  ];

  // Thay đổi Page
  const onPageChange = (p: number) => {
    setPage(p);
    setFilter({ ...filter, page: p });
  };

  // Tháng/Năm
  const onChangeRangePicker = (dates: [any, any], dateRanger: [string, string]) => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({
      ...filter,
      minDate: dates && dates.length ? dates[0].toISOString() : undefined,
      maxDate: dates && dates.length ? dates[1].toISOString() : undefined,
    });
  };

  return (
    <>
      <Row>
        <ConfigProvider locale={locale}>
          <Col span={6}>
            <RangePicker
              picker="month"
              style={{ width: '100%' }}
              placeholder={['Tháng/Năm', 'Tháng/Năm']}
              onChange={onChangeRangePicker}
            />
          </Col>
        </ConfigProvider>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col span={24}>
          <Row>
            {loading ? (
              <PageLoading />
            ) : (
              <Col span={24}>
                <Table
                  key={1}
                  dataSource={dataHistory || []}
                  columns={columns}
                  pagination={false}
                  scroll={{ x: 'auto' }}
                  rowKey="_id"
                />
              </Col>
            )}
            <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              <div className="d-flex justify-content-end pagination">
                <Pagination
                  showSizeChanger={false}
                  total={totalHistory}
                  current={page}
                  defaultCurrent={page}
                  defaultPageSize={PaginateList.LIMIT_DEFAULT}
                  onChange={onPageChange}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default HistoryUsedPointScreen;
