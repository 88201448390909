/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable react-native/no-inline-styles */
import { useDebounceFn } from 'ahooks';
import { Button, Col, InputNumber, Pagination, Row, Table, Typography } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { formatNumberPrice } from 'helpers/FormatPrice';
import React, { useEffect, useState } from 'react';
import { ROUTER_PATH } from 'routes/Path';
import { setTimeout } from 'timers';
import useAnalysisService from '../analysis.service';

const { Column, ColumnGroup } = Table;
const { Text, Link } = Typography;

export interface USER_F1 {
  page: number | string;
  limit: number | string;
  minDate?: string;
  maxDate?: string;
  exportExcel?: boolean;
}

function ProfitComponent(): JSX.Element {
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<USER_F1>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    minDate: undefined,
    maxDate: undefined,
    exportExcel: undefined,
  });

  const { getProfit, dataProfit, totalProfit, loadingProfit, summaryProfit } = useAnalysisService();
  useEffect(() => {
    getProfit(filter);
  }, [filter]);

  const columns = [
    {
      title: 'STT',
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Năm',
      dataIndex: 'year',
      render: (year: string) => {
        return <span>{year}</span>;
      },
    },
    {
      title: 'Tháng',
      dataIndex: 'month',
      render: (month: string) => {
        return <span>{month}</span>;
      },
    },
    {
      title: 'Dự án',
      dataIndex: 'fromProject',
      render: (fromProject: number) => {
        return <span>{formatNumberPrice(fromProject)}</span>;
      },
    },
    {
      title: 'BĐS lẻ',
      dataIndex: 'fromProjectNews',
      render: (fromProjectNews: number) => {
        return <span>{formatNumberPrice(fromProjectNews)}</span>;
      },
    },
    {
      title: 'Nạp điểm',
      dataIndex: 'fromPointPackage',
      render: (fromPointPackage: number) => {
        return <span>{formatNumberPrice(fromPointPackage)}</span>;
      },
    },
  ];

  const onPageChange = (perPage: number) => {
    setPage(perPage);
    setFilter({ ...filter, page: perPage });
  };

  const onClickExcel = () => {
    setFilter({ ...filter, exportExcel: true });
    setTimeout(() => {
      setFilter({ ...filter, exportExcel: undefined });
    }, 1000);
  };

  const summaryProfits = [
    {
      key: '1',
      profitFromProject: `${new Intl.NumberFormat('vi-VN').format(summaryProfit?.profitFromProject)} đồng`,
      profitFromProjectNews: `${new Intl.NumberFormat('vi-VN').format(summaryProfit?.profitFromProjectNews)} đồng`,
      profitFromPointPackage: `${new Intl.NumberFormat('vi-VN').format(summaryProfit?.profitFromPointPackage)} đồng`,
      totalProfit: `${new Intl.NumberFormat('vi-VN').format(summaryProfit?.totalProfit)} đồng`,
    },
  ];

  return (
    <>
      <Row style={{ marginBottom: '17px' }}>
        <Col span={20} style={{ fontSize: '20px' }}>
          <b>Lợi nhuận của Phúc An Gia </b>
        </Col>
        <Col span={4}>
          <Button type="primary" onClick={onClickExcel}>
            Xuất file excel
          </Button>
        </Col>
      </Row>
      <Row>
        {loadingProfit ? (
          <PageLoading />
        ) : (
          [
            <Col span={24}>
              <Table
                key={1}
                dataSource={dataProfit || []}
                columns={columns}
                pagination={false}
                scroll={{ x: 'auto' }}
                rowKey="_id"
                bordered
              />
            </Col>,
            <Col span={24}>
              <Table dataSource={summaryProfits} bordered pagination={false}>
                <Column title="Tồng từ dự án" dataIndex="profitFromProject" key="profitFromProject" />
                <Column title="Tổng từ BĐS lẻ" dataIndex="profitFromProjectNews" key="profitFromProjectNews" />
                <Column title="Tổng từ nạp điểm" dataIndex="profitFromPointPackage" key="profitFromPointPackage" />
                <Column title="Tổng doanh thu" dataIndex="totalProfit" key="totalProfit" />
              </Table>
            </Col>,
          ]
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalProfit}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ProfitComponent;
